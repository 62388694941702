import { some } from 'lodash';

import type { HeaderNavItems } from '@/lib/constants/marketing-site';

// Check if the current URL is active in the navigation item
// so we can highlight the parent nav item
export const isUrlActiveInNavItem = (item: HeaderNavItems, currentPath: string): boolean => {
  const urlsToCheck: string[] = [
    // Check main item URL
    item.url,
    // Check direct links
    ...(item.links?.map((link) => link.url) || []),
    // Check cards
    ...(item.cards?.flatMap((card) => card.items.map((cardItem) => cardItem.url)) || []),
    // Check mini links
    ...(item.linksMini?.flatMap((section) => section.links.map((link) => link.url)) || []),
  ].filter(Boolean);

  return some(urlsToCheck, (url) => url === currentPath);
};
