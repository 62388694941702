import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { isEmpty, kebabCase } from 'lodash';
import { ChevronDown } from 'lucide-react';
import { usePathname, useSearchParams } from 'next/navigation';
import { Fragment, useEffect, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useRecoilState } from 'recoil';

import { Container } from '@/components/container';
import { Link } from '@/components/link';
import { Logo } from '@/components/logo';
import { NavToggle } from '@/components/nav-toggle';
import { UserNav } from '@/components/user-nav';
import { headerNav } from '@/lib/constants/marketing-site';
import { navIsOpenState } from '@/state/navigation';
import { PostSnippets } from '@/types/cms-types';
import { tv } from '@/utils/styles';

import { NavCards } from './nav/cards';
import { NavLinks } from './nav/links';
import { NavLinksMini } from './nav/links-mini';
import { NavMobile } from './nav/mobile';
import { NavPosts } from './nav/posts';
import { isUrlActiveInNavItem } from './utils';

export interface PageHeaderProps {
  variant?: 'light' | 'dark';
  posts?: PostSnippets;
  initialActiveItem?: string | undefined; // Used only to open items for Storybook snapshots
}

const PageHeader = ({ variant = 'light', posts, initialActiveItem = undefined }: PageHeaderProps) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [navIsOpen, setNavIsOpen] = useRecoilState(navIsOpenState);
  const [activeNavItem, setActiveNavItem] = useState<string | undefined>(initialActiveItem);

  useEffect(() => {
    // Any time the route changes, close nav
    setNavIsOpen(false);
  }, [pathname, searchParams]);

  const ref = useOnclickOutside(() => {
    setNavIsOpen(false);
  });

  const {
    base,
    container,
    logoLink,
    wrapper,
    navMenuRoot,
    navMenuList,
    navMenuParentListItemDesktop,
    navMenuParentListItemMobile,
    navMenuContent,
    navMenuContentSplit,
    navViewportPosition,
    navViewport,
    icon,
    navLink,
    navLinkBg,
    navLinkInner,
  } = styles({
    variant,
    navIsOpen,
  });

  return (
    <header className={base()} ref={ref}>
      <Container className={container()}>
        <div className={wrapper()}>
          <Link href="/" className={logoLink()}>
            <Logo variant="colored" />
          </Link>

          <NavigationMenu.Root
            delayDuration={100}
            className={navMenuRoot()}
            value={activeNavItem}
            onValueChange={(value) => setActiveNavItem(value)}
          >
            <NavigationMenu.List className={navMenuList()}>
              {headerNav.map((item) => {
                if (item.isHidden) return null;
                if (!isEmpty(item.links) || !isEmpty(item.linksMini) || item.cards) {
                  const anyChildActive = isUrlActiveInNavItem(item, pathname);
                  const value = kebabCase(item.label);

                  return (
                    <Fragment key={`${kebabCase(item.label)}-parent-nav`}>
                      {/* Desktop only */}
                      <NavigationMenu.Item className={navMenuParentListItemDesktop()} value={value}>
                        <NavigationMenu.Trigger
                          className={navLink({ navLinkIsActive: anyChildActive })}
                          data-cy="marketing-nav-subnav-trigger"
                        >
                          <span className={navLinkInner()}>
                            {item.label}
                            <ChevronDown size={14} className={icon({ className: '-mr-0.5 ml-1' })} />
                          </span>
                          <span className={navLinkBg({ navLinkIsActive: anyChildActive })} />
                        </NavigationMenu.Trigger>
                        <NavigationMenu.Content className={navMenuContent()} data-cy="marketing-nav-subnav">
                          {item.cards && <NavCards cards={item.cards} pathname={pathname} />}
                          {(item.links || item.linksMini) && (
                            <div className={navMenuContentSplit({ className: item.className })}>
                              {item.links && (
                                <NavLinks items={item.links} pathname={pathname} className={item.className} />
                              )}
                              {item.linksMini && <NavLinksMini items={item.linksMini} pathname={pathname} />}
                              {item.posts && posts && !isEmpty(posts[item.posts]) && (
                                <NavPosts posts={posts[item.posts]} header={item.subSectionHeader} />
                              )}
                            </div>
                          )}
                        </NavigationMenu.Content>
                      </NavigationMenu.Item>
                    </Fragment>
                  );
                }

                return (
                  <NavigationMenu.Item
                    key={`${kebabCase(item.label)}-nav`}
                    className={navMenuParentListItemMobile({ className: 'md:block' })}
                  >
                    <NavigationMenu.Link active={pathname === item.url} asChild>
                      <Link href={item.url} className={navLink({ navLinkIsActive: pathname === item.url })}>
                        <span className={navLinkInner()}>{item.label}</span>
                        <span className={navLinkBg({ navLinkIsActive: pathname === item.url })} />
                      </Link>
                    </NavigationMenu.Link>
                  </NavigationMenu.Item>
                );
              })}
            </NavigationMenu.List>

            <NavMobile headerNav={headerNav} navIsOpen={navIsOpen} />
            <UserNav variant={variant} hideUsageOnSmallScreens />

            <div className={navViewportPosition()}>
              <NavigationMenu.Viewport className={navViewport()} />
            </div>
          </NavigationMenu.Root>

          <NavToggle variant={variant} />
        </div>
      </Container>
    </header>
  );
};

const styles = tv({
  slots: {
    base: 'absolute z-5 w-full',
    container: 'max-md:px-4',
    logoLink:
      'logo-link [&_svg_path]:delay-[0.12s] relative z-5 mr-8 flex items-center [&_svg_path]:transition-all [&_svg_path]:duration-300 [&_svg_path]:ease-in-out',
    wrapper: 'flex items-center justify-between pb-6 pt-5 md:grid md:grid-cols-[min-content,1fr,min-content] lg:pt-8',
    navMenuRoot:
      'relative flex justify-between max-md:absolute max-md:left-0 max-md:top-0 max-md:w-full max-md:-translate-y-full max-md:flex-col max-md:bg-grey-100 max-md:px-2 max-md:py-4 max-md:pt-20 max-md:[transition:_transform_0.35s_ease-in-out,box-shadow_0.2s_ease-in-out_0.2s]',
    navMenuList:
      'flex items-center justify-center max-md:w-full max-md:flex-col max-md:opacity-0 max-md:transition-opacity max-md:delay-0 max-md:ease-in-out md:gap-1',
    navMenuParentListItemDesktop: 'hidden md:block',
    navMenuParentListItemMobile:
      'hidden max-md:w-full max-md:border-b max-md:border-grey-200 max-md:pl-2 max-md:last:mb-0 max-md:last:border-b-0 md:hidden',
    navMenuContent:
      'z-1 sm:w-auto md:absolute md:left-0 md:top-0 md:data-[motion=from-end]:animate-enter-from-right md:data-[motion=from-start]:animate-enter-from-left md:data-[motion=to-end]:animate-exit-to-right md:data-[motion=to-start]:animate-exit-to-left',
    nestedList: 'mb-4 mt-1 grid h-auto gap-px md:m-0 md:p-4',
    navMenuContentSplit: 'grid w-[700px] items-start gap-5 md:grid-cols-2',
    subLink:
      'text-decoration-none relative flex rounded pr-2 md:p-4 [&_*]:relative [&_*]:z-2 [&_.sub-link-bg]:hover:scale-100 [&_.sub-link-bg]:hover:opacity-100 [&_.sub-link-icon]:hover:text-lightBlue-500',
    subLinkBg:
      'sub-link-bg !absolute bottom-0 left-0 right-0 top-0 !z-1 block scale-[.95] rounded border border-grey-300 bg-white opacity-0 shadow-soft transition-all duration-100 ease-in-out',
    subLinkHeading: 'mb-0 text-blue-500',
    subLinkIcon: 'sub-link-icon mr-2',
    subLinkMini:
      'group flex items-center text-baseSm font-medium text-brandDarkBlue transition-colors hover:text-lightBlue-500',
    rightChevron:
      'translate-x-0 opacity-0 transition-[opacity,transform] group-hover:translate-x-1 group-hover:opacity-100',
    navViewportPosition: 'absolute left-0 top-[calc(100%+8px)] flex justify-center perspective-[2000px]',
    navViewport:
      'relative h-[var(--radix-navigation-menu-viewport-height)] w-full origin-top overflow-hidden rounded-md bg-white shadow-lg transition-[width,height_300ms_ease] data-[state=closed]:animate-scale-out data-[state=open]:animate-scale-in sm:w-[var(--radix-navigation-menu-viewport-width)]',
    icon: 'down-icon ml-1 transition-all',
    navLink:
      'text-decoration-none text-inherit relative flex w-full font-medium leading-tight max-md:py-2 md:p-2 md:text-baseSm [&_.down-icon]:data-[state=open]:rotate-180 [&_.nav-link-bg]:hover:scale-[1.02] [&_.nav-link-bg]:hover:opacity-100 [&_.nav-link-bg]:data-[state=open]:scale-[1.02] [&_.nav-link-bg]:data-[state=open]:opacity-100',
    navLinkInner: 'relative z-2 flex w-full items-center justify-between truncate',
    navLinkBg:
      'nav-link-bg pointer-events-none absolute bottom-0 left-0 right-0 top-0 block scale-90 rounded opacity-0 transition-all duration-100 ease-in-out max-md:hidden',
    cardLink: 'text-decoration-none group',
  },
  variants: {
    variant: {
      light: {
        navLink: 'text-blue-700 md:text-white',
        navMenuParentListItemDesktop: 'text-blue-700 md:text-white',
        icon: '[&_svg]:fill-white',
        logoLink: 'md:[&_svg_path]:fill-white',
        navLinkBg: 'bg-blue-700',
      },
      dark: {
        navLink: 'text-blue-700',
        navMenuParentListItemDesktop: 'text-blue-700 md:text-white',
        icon: '[&_svg]:fill-blue-700',
        logoLink: 'md:[&_svg_path]:fill-blue-700',
        navLinkBg: 'bg-[#d5e4ec]',
        navViewport: 'border border-grey-200',
      },
    },
    navIsOpen: {
      true: {
        navMenuRoot: 'max-md:translate-y-0',
        navMenuList: 'max-md:opacity-1 max-md:delay-300',
      },
      false: null,
    },
    navLinkIsActive: {
      true: {
        navLinkBg: 'scale-[1.02] opacity-100',
      },
    },
    subLinkIsActive: {
      true: {
        subLink: 'max-md:[&_span:first-of-type]:underline',
        subLinkBg: 'scale-100 opacity-100',
      },
    },
  },
  compoundVariants: [
    {
      navIsOpen: false,
      variant: 'light',
      class: 'max-md:[&_.logo-link_svg_path]:fill-white',
    },
  ],
});

export { PageHeader };
